import React, { useState, useEffect } from "react";
import { Navbar, NavDropdown, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from "../../../../components/Image";
import { useSelector, useDispatch } from "react-redux";
import Service from "../../../../http";
import { useNavigate } from "react-router-dom";
import { clearState } from "../../../../redux/loginSlice";

import { themeColor1, themeColor2 } from "../../../../components/utils";
import { logoutUser } from "../../../../redux/logoutSlice";
import { Link as ScrollLink } from "react-scroll";
import CustomDropdown from "../../CustomDropdown";
import { notify } from "../../../../components/Notification";

export default function Header1() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [profile, setProfile] = useState();
  const loginState = useSelector(state => state.login);
  const frontendState = useSelector(state => state.frontend.customizationData);
  const services = new Service();

  const logout = () => {
    dispatch(logoutUser());

    window.localStorage.clear();
    notify("Logout successfully", "error");
    if (loginState.user_type === "player") {
      navigate("/player-login");
    } else if (loginState.user_type === "coach") {
      navigate("/coach-login");
    } else if (loginState.user_type === "academy") {
      navigate("/academy-login");
    }
    dispatch(clearState());
  };

  useEffect(() => {
    if (loginState.user_type === "player") {
      services &&
        services
          .get("player/details")
          .then(res => {
            setProfile(res.data);
          })
          .catch(error => {
            if (error.response.statusText === "Unauthorized") {
              localStorage.clear();
              logout();
            }
          });
    } else if (loginState.user_type === "coach") {
      services &&
        services
          .get("coach/details")
          .then(res => {
            setProfile(res.data);
          })
          .catch(error => {
            if (error.response.statusText === "Unauthorized") {
              localStorage.clear();
              logout();
            }
          });
    } else if (loginState.user_type === "academy") {
      services &&
        services
          .get("academy/details")
          .then(res => {
            setProfile(res.data);
          })
          .catch(error => {
            if (error.response.statusText === "Unauthorized") {
              localStorage.clear();
              logout();
            }
          });
    }
  }, []);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function wc_hex_is_light(color) {
    const hex = color.replace("#", "");
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

    return brightness > 155;
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav className={`${scrolled && "scrolled"} fixed-top top-0 px-3 py-[10px] md:pb-[40px] md:pt-[30px]`}>
        <div className="container mx-auto hidden items-center justify-center md:flex">
          <div className="flex space-x-5">
            <Link to="/home" className="">
              Home
            </Link>

            {loginState.user_type === "player" ||
            loginState.user_type === "coach" ||
            loginState.user_type === "administrator" ||
            loginState.user_type === "academy" ? (
              <>
                <Link to="/dashboard">Dashboard</Link>
                <Link to="/tournaments/tournaments-view" className="cursor-pointer ">
                  Tournament
                </Link>
              </>
            ) : (
              <ScrollLink to="tournament-calendar" smooth={true} className="cursor-pointer " offset={-50}>
                Tournament
              </ScrollLink>
            )}

            <ScrollLink to="players" smooth={true} className="cursor-pointer " offset={-50}>
              Players
            </ScrollLink>
          </div>
          <div className="px-5 text-xl font-bold ">
            <Link to="/" className="">
              <Image src={frontendState?.logo} height={50} />
            </Link>
          </div>
          <div className="flex space-x-5">
            <ScrollLink to="about" smooth={true} className="cursor-pointer " offset={-50}>
              About Us
            </ScrollLink>

            {loginState.user_type === "player" ||
            loginState.user_type === "coach" ||
            loginState.user_type === "administrator" ||
            loginState.user_type === "academy" ? (
              <>
                <CustomDropdown
                  title={<>My Account</>}
                  options={[
                    {
                      label: frontendState ? frontendState.first_name + " " + frontendState.family_name : "Player",
                      link: "/my-profile",
                    },
                    {
                      label: "Logout",
                      link: "#",
                      onClick: logout,
                    },
                  ]}
                />
              </>
            ) : (
              <>
                <CustomDropdown
                  title={<>Login</>}
                  options={[
                    {
                      label: "Player Login",
                      link: "/player-login",
                    },
                    {
                      label: "Coach Login",
                      link: "/coach-login",
                    },
                    {
                      label: "Academy Login",
                      link: "/academy-login",
                    },
                    {
                      label: "Club Member Login",
                      link: "/club-member-login",
                    },
                  ]}
                />

                <CustomDropdown
                  title={<>Register</>}
                  options={[
                    {
                      label: "Player",
                      link: "/player-register",
                    },
                    {
                      label: "Coach",
                      link: "/coach-register",
                    },
                    {
                      label: "Club Member",
                      link: "/club-member-register",
                    },
                  ]}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex items-center md:hidden">
          <div className="w-100 grow-1 text-xl font-bold ">
            <Image src={frontendState?.logo} height={35} />
          </div>
          <div className="">
            <button
              onClick={() => {
                setIsOpen(!isOpen);
                setScrolled(true);
              }}
              className="border-0 bg-transparent">
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
              </svg>
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden">
            <div className="mt-4 flex flex-col space-y-4">
              <Link to="/" className="">
                Home
              </Link>

              {loginState.user_type === "player" ||
              loginState.user_type === "coach" ||
              loginState.user_type === "administrator" ||
              loginState.user_type === "academy" ? (
                <Link to="/dashboard" className="">
                  Dashboard
                </Link>
              ) : (
                <Link to="/tournaments" className="">
                  Tournaments
                </Link>
              )}

              <ScrollLink to="players" smooth={true} className="" offset={-70}>
                Players
              </ScrollLink>

              <ScrollLink to="about" smooth={true} className="" offset={-70}>
                About Us
              </ScrollLink>

              {loginState.user_type === "player" ||
              loginState.user_type === "coach" ||
              loginState.user_type === "administrator" ||
              loginState.user_type === "academy" ? (
                <>
                  <CustomDropdown
                    title={<>My Account</>}
                    options={[
                      {
                        label: frontendState ? frontendState.first_name + " " + frontendState.family_name : "Player",
                        link: "/my-profile",
                      },
                      {
                        label: "Logout",
                        // link: "",
                        onClick: logout,
                      },
                    ]}
                  />
                </>
              ) : (
                <>
                  <CustomDropdown
                    title={<>Login</>}
                    options={[
                      {
                        label: "Player Login",
                        link: "/player-login",
                      },
                      {
                        label: "Coach Login",
                        link: "/coach-login",
                      },
                      {
                        label: "Academy Login",
                        link: "/academy-login",
                      },
                      {
                        label: "Club Member Login",
                        link: "/club-member-login",
                      },
                    ]}
                  />

                  <CustomDropdown
                    title={<>Register</>}
                    options={[
                      {
                        label: "Player",
                        link: "/player-register",
                      },
                      {
                        label: "Coach",
                        link: "/coach-register",
                      },
                      {
                        label: "Club Member",
                        link: "/club-member-register",
                      },
                    ]}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </nav>

      <style>{`
        nav {
          background: rgb(0,0,0);
          background: -moz-linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
          background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
          background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
          transition: all .3s;
        }

        nav a {
          color:#fff;
        }

        nav.scrolled {
          background: #fff !important;
          padding: 10px;
          box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
        }
   
        nav.scrolled a {
          color:#000 !important;
        }
      `}</style>
    </>
  );
}
